import React, { useState, createContext } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import "./App.css";
import clsx from 'clsx';
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ConfirmSignUp from "./components/ConfirmSignUp";
import Home from "./components/Home";
import Attributes from "./components/Attributes";
import ListItems from './components/ListItems';
import PrivateRoute from './components/PrivateRoute';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AccountCircle, Explore, Home as HomeIcon } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const INITIAL_ATTRIBUTES = {
    isSigned: false,
    userName: '',
    email: '',
    phoneNumber: '',
    cmicId: '',
    jwtToken: ''
}

export const SignedContext = createContext();

const App = () => {

    const [attributes, setAttributes] = useState(INITIAL_ATTRIBUTES);
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const theme = useTheme();

    const handleDrawerOpen = () => {
        setOpen(true);
    }
    const handleDrawerClose = () => {
        setOpen(false);
    }
    const signOut = async () => {
        await Auth.signOut();
        setAttributes(INITIAL_ATTRIBUTES);
    }

    return (

        <BrowserRouter>
            <div className={classes.root}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Typography variant="h6" className={classes.title}>
                            Cognito App
                        </Typography>
                        <Button color="inherit" onClick={signOut}>Logout</Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <ListItem button component={Link} to="/" onClick={handleDrawerClose}>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button component={Link} to="/attributes" onClick={handleDrawerClose}>
                            <ListItemIcon><AccountCircle /></ListItemIcon>
                            <ListItemText primary="Accounts" />
                        </ListItem>
                        <ListItem button component={Link} to="/listitems" onClick={handleDrawerClose}>
                            <ListItemIcon><Explore /></ListItemIcon>
                            <ListItemText primary="List Items" />
                        </ListItem>
                    </List>
                </Drawer>
            </div>
            <SignedContext.Provider value={attributes}>
                <Switch>
                    <Route exact path="/login" render={() => <SignIn setAttributes={setAttributes} />} />
                    <Route exact path="/signup" render={() => <SignUp setAttributes={setAttributes} />} />
                    <Route exact path="/confirm" render={() => <ConfirmSignUp />} />
                    <PrivateRoute path="/attributes" component={Attributes} />
                    <PrivateRoute path="/listitems" component={ListItems} />
                    <PrivateRoute path="/" component={Home} />
                </Switch>
            </SignedContext.Provider>
        </BrowserRouter>

    );

}

export default App;
