import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AlertDialog from './AlertDialog';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                adachi.com
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const INITIAL_USER = {
    userId: '',
    email: ''
}

const SignIn = (props) => {
    const [user, setUser] = useState(INITIAL_USER);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const classes = useStyles();

    const handleTextFieldChanges = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(user.userId + ';' + user.password);
        Auth.signIn(user.userId, user.password)
            .then(async (data) => {
                console.log("Sign In Success!");
                const authUser = await Auth.currentAuthenticatedUser();
                console.log(authUser);
                //                console.log("access_token: " + authUser.signInUserSession.accessToken.jwtToken);
                const userInfo = await Auth.currentUserInfo();
                console.log(userInfo.attributes);
                props.setAttributes({
                    isSigned: true,
                    userName: authUser['username'],
                    email: userInfo.attributes['email'],
                    phoneNumber: userInfo.attributes['phone_number'],
                    cmicId: userInfo.attributes['custom:cmic_id'],
                    jwtToken: authUser.signInUserSession.idToken.jwtToken
                });
                props.history.push('/');
            })
            .catch((err) => {
                console.log("Sign In Failed.");
                console.log(err);
                setOpen(true);
                return;
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
        </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="userId"
                        label="User ID"
                        name="userId"
                        //                        autoComplete="email"
                        autoFocus
                        onChange={handleTextFieldChanges}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleTextFieldChanges}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Sign In
          </Button>
                    <Grid container justify="center">
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <AlertDialog
                open={open}
                onClose={handleClose}
                alertMessage="ログインに失敗しました。"
                alertDescription="ID,PASSWORDを確認してください。" />
        </Container>
    );
}

export default withRouter(SignIn);