import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from "aws-amplify";
import reportWebVitals from './reportWebVitals';

Amplify.configure({
  Auth: {
    indentityPoolId: "ap-northeast-1:0fafb64d-6361-4d89-9f3d-b18097b87a46",
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_3oNQ159SX",
    userPoolWebClientId: "1nng6n29r0q7lgba7d3g7d00ms"
  }
})
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
