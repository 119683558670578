import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                adachi.com
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const INITIAL_USER = {
    userId: '',
    password: '',
    email: '',
    phone: '',
    cmicId: ''
}

const SignUp = (props) => {
    const [user, setUser] = useState(INITIAL_USER);
    const classes = useStyles();

    const handleTextFieldChanges = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    }

    const cognitoSignUp = async (user) => {
        const signUpUser = await Auth.signUp({
            username: user.userId,
            password: user.password,
            attributes: {
                email: user.email,
                phone_number: user.phone,
                'custom:cmic_id': user.cmicId
            }
        });
        console.log(signUpUser);
        return signUpUser;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(user.userId + ';' + user.password);

        await cognitoSignUp(user)
            .then(response => {
                console.log("Sign Up Success. ", response.user.username);
                props.setAttributes({ ...user, userName: response.user.username })
                props.history.push('/confirm');
            })
            .catch(error => {
                console.log("Sign Up Error!", error);
                return;
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AccountCircleIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
        </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="userId"
                        label="User ID"
                        name="userId"
                        //                        autoComplete="email"
                        autoFocus
                        onChange={handleTextFieldChanges}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleTextFieldChanges}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        //                        autoComplete="email"
                        onChange={handleTextFieldChanges}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        onChange={handleTextFieldChanges}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="cmicId"
                        label="BC ID"
                        name="cmicId"
                        onChange={handleTextFieldChanges}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Sign Up
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(SignUp);