import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const TableRow = (props) => {
    return (
        <Grid item xs={12} >
            <Grid container justify="center" wrap="wrap" spacing={3}>
                <Grid item xs={4}>
                    <Typography component="p" variant="body1">{props.itemName}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="p" variant="body1" noWrap>{props.itemValue}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TableRow;