import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SignedContext } from '../App';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const attributes = useContext(SignedContext);
    console.log("RrivateRoute:" + attributes.isSigned);
    return (
        <Route {...rest} render={props => (attributes.isSigned
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    )
}

export default PrivateRoute;