import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chart from 'react-apexcharts';
import { Container } from '@material-ui/core';
import config from '../config/config.json';

const API = config.YAHOO.API;
const API_HOST = config.YAHOO.API_HOST;
const API_KEY = config.YAHOO.API_KEY;
const REGION = "US";
const INTERVAL = "1d";
const SYMBOL = "MSFT";
const RANGE = "3mo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    graphAria: {
        marginTop: theme.spacing(16),
    }
}));

const Home = () => {
    const classes = useStyles();
    const [stockData, setStockData] = useState({});
    const mounted = useRef(false);

    const getChart = async () => {
        const url = `${API}?region=${REGION}&interval=${INTERVAL}&symbol=${SYMBOL}&range=${RANGE}`;
        console.log("url: " + url);
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                "x-rapidapi-host": API_HOST,
                "x-rapidapi-key": API_KEY
            }
        });
        return response;
    }

    const getTimestamp = (chart) => {
        const timestamp = chart.timestamp.map((data, index) => {
            return data * 1000;
        });
        return timestamp;
    }

    const getIndicators = (chart) => {
        const indicators = chart.indicators.quote[0].close.map((data, index) => {
            return Math.round(data * 1000) / 1000;
        })
        return indicators;
    }

    useEffect(() => {
        const createStockData = (chart) => {
            console.log(chart);
            const timestamp = getTimestamp(chart);
            const indicators = getIndicators(chart);
            const stockData = {
                options: {
                    chart: {
                        zoom: {
                            enabled: false
                        },
                        id: 'stock-data'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: timestamp,
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                            minHeight: 60,
                            format: 'yyyy/MM/dd'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Closing Price'
                        }
                    },
                    title: {
                        text: 'Microsoft Stock Price',
                        align: 'center',
                        style: {
                            fontSize: '20px'
                        }
                    }
                },
                series: [{
                    name: 'series-1',
                    data: indicators
                }]
            };
            return stockData;
        }

        if (mounted.current) {
            console.log('useEffect(DidUpdate)');
        } else {
            console.log('useEffect(DidMount) execute.');
            const dataDisplay = async () => {
                await getChart()
                    .then(response => response.json())
                    .then(response => {
                        const chart = response.chart.result[0];
                        setStockData(createStockData(chart));
                    })
                    .catch(err => {
                        console.log("Yahoo fetch error.");
                    });
            }
            dataDisplay();
            mounted.current = true;
        }
    }, [stockData]);

    return (
        <Container fixed>
            {
                (() => {
                    if (Object.entries(stockData).length === 0 && stockData.constructor === Object) {
                        return <Typography className={classes.graphAria} variant="body1" align="center">
                            Getting Stock Information...
                            </Typography>
                    } else {
                        return <Chart className={classes.graphAria} options={stockData.options} series={stockData.series} type="line" /*width={500} height={320} */ />;
                    }
                })()
            }

        </Container>
    );
}

export default Home;