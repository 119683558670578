import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { SignedContext } from '../App';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import MailIcon from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                adachi.com
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ConfirmSignUp = (props) => {
    const [verifyCode, setVerifyCode] = useState('');
    const attributes = useContext(SignedContext);
    const classes = useStyles();

    const handleTextFieldChanges = (e) => {
        const { value } = e.target;
        setVerifyCode(value);
    }

    const cognitoConfirmSignUp = async () => {
        await Auth.confirmSignUp(
            attributes.userName,
            verifyCode
        );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('verify code: ', verifyCode);

        cognitoConfirmSignUp()
            .then(response => {
                console.log("Confirm Success.");
                props.history.push('/login');
            })
            .catch(error => {
                console.log("Confirm Error!", error);
                return;
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <MailIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Confirm Sign Up
        </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="verifyCode"
                        label="Verify Code"
                        name="verifyCode"
                        //                        autoComplete="email"
                        autoFocus
                        onChange={handleTextFieldChanges}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Confirm Sign Up
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(ConfirmSignUp);