import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { SignedContext } from '../App';
import MySwitch from './MySwitch';
import MyDialog from './MyDialog';
import config from '../config/config.json';

const API = config.AWS.API;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grid: {
        marginTop: theme.spacing(10),
    },
}));


const INITIAL_AGREEMENTS = {
    genome: false,
    receipt: false,
    health: false
}

const ListItems = () => {
    const classes = useStyles();
    const attributes = useContext(SignedContext);
    const [agreements, setAgreements] = useState(INITIAL_AGREEMENTS);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const getItem = async (jwtToken) => {
        const response = await fetch(API, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`
            }
        });
        return response;
    }

    const putItem = async (agreements) => {
        const response = await fetch(API, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${attributes.jwtToken}`
            },
            body: JSON.stringify({
                'agreements': agreements
            })
        });
        return response;
    }

    useEffect(() => {
        const dataDisplay = async () => {
            console.log(attributes.jwtToken);
            await getItem(attributes.jwtToken)
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } if (response.status === 201) {
                        return { agreements: INITIAL_AGREEMENTS };
                    }
                    else {
                        throw new Error();
                    }
                })
                .then(response => {
                    console.log(response);
                    setAgreements(response.agreements);
                })
                .catch(err => {
                    console.log("fetch error.");
                });
        }
        dataDisplay();
    }, [attributes.jwtToken]);

    const handleChange = (event) => {
        console.log(event.target.name + "Switch clicked.");
        setAgreements({ ...agreements, [event.target.name]: event.target.checked });
        setOpen(true);
    };

    const handleClickYes = () => {
        console.log("Yes clicked.");
        setOpen(false);
        putItem(agreements)
            .then(response => response.json())
            .then(response => {
                console.log(response);
            })
            .catch(err => {
                console.log("putItem error.");
            });
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.grid} spacing={3}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={3}>
                        <Typography component="h1" variant="h4">
                            同意状況
                        </Typography>
                    </Grid>
                </Grid>
                <MySwitch name="genome" caption="ゲノム情報" checked={agreements.genome} onChange={handleChange} />
                <MySwitch name="receipt" caption="レセプト情報" checked={agreements.receipt} onChange={handleChange} />
                <MySwitch name="health" caption="健康診断結果" checked={agreements.health} onChange={handleChange} />
                <MyDialog open={open} onClose={handleClose} handleClickYes={handleClickYes} />
            </Grid>
        </div>
    );
}

export default ListItems;