import React, { useContext } from 'react';
import { SignedContext } from '../App';
import TableRow from './TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    h1: {
        marginTop: theme.spacing(10),
    },
    avatar: {
        marginTop: theme.spacing(3),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const Attributes = () => {
    const classes = useStyles();
    const attributes = useContext(SignedContext);
    return (
        <div className={classes.root}>
            <Grid container className={classes.grid} spacing={3}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={3}>
                        <Typography className={classes.h1} component="h1" variant="h4">
                            認証情報
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={3}>
                        <Avatar className={classes.avatar + " " + classes.large} src="static/images/avator_peanuts.jpg" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={4}>
                            <Typography variant="body1" align="center">
                                <Box fontWeight="fontWeightBold">
                                    項目
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" align="center">
                                <Box fontWeight="fontWeightBold">
                                    値
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <TableRow itemName='ユーザ名' itemValue={attributes['userName']} />
                <TableRow itemName='Eメール' itemValue={attributes['email']} />
                <TableRow itemName='電話番号' itemValue={attributes['phoneNumber']} />
                <TableRow itemName='BC-ID' itemValue={attributes['cmicId']} />
                <TableRow itemName='トークン' itemValue={attributes['jwtToken']} />
            </Grid>
        </div>
    );
}

export default Attributes;